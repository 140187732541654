<template>
  <iframe class="video-editor" src="https://video.useagencyai.com"
    allow="camera; microphone; fullscreen; display-capture; autoplay; clipboard-read; clipboard-write" />
</template>
<script>
export default {
  name: 'VideoEditor',
};
</script>
<style lang="scss" scoped>
.video-editor {
  width: 100%;
  margin-top: -20px;
  height: calc(100vh - 55px);
  border: none;
}
</style>
